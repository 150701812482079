// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-cookie-statement-index-js": () => import("./../../../src/pages/en/CookieStatement/index.js" /* webpackChunkName: "component---src-pages-en-cookie-statement-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-index-js": () => import("./../../../src/pages/en/PrivacyPolicy/index.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-index-js" */),
  "component---src-pages-en-terms-of-service-index-js": () => import("./../../../src/pages/en/TermsOfService/index.js" /* webpackChunkName: "component---src-pages-en-terms-of-service-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-zh-cookie-statement-index-js": () => import("./../../../src/pages/zh/CookieStatement/index.js" /* webpackChunkName: "component---src-pages-zh-cookie-statement-index-js" */),
  "component---src-pages-zh-index-js": () => import("./../../../src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-pages-zh-privacy-policy-index-js": () => import("./../../../src/pages/zh/PrivacyPolicy/index.js" /* webpackChunkName: "component---src-pages-zh-privacy-policy-index-js" */),
  "component---src-pages-zh-terms-of-service-index-js": () => import("./../../../src/pages/zh/TermsOfService/index.js" /* webpackChunkName: "component---src-pages-zh-terms-of-service-index-js" */)
}

